/* global window, Backbone, $, arangoHelper */
(function () {
  'use strict';

  window.arangoViewModel = Backbone.Model.extend({
    idAttribute: 'name',
    urlRoot: arangoHelper.databaseUrl('/_api/view'),
    defaults: {
      id: '',
      name: '',
      type: '',
      globallyUniqueId: ''
    },
    getProperties: function getProperties(callback) {
      $.ajax({
        type: 'GET',
        cache: false,
        url: arangoHelper.databaseUrl('/_api/view/' + encodeURIComponent(this.get('name')) + '/properties'),
        contentType: 'application/json',
        processData: false,
        success: function success(data) {
          callback(false, data);
        },
        error: function error(data) {
          callback(true, data);
        }
      });
    },
    patchProperties: function patchProperties(reqData, callback) {
      var self = this;
      $.ajax({
        type: 'PUT',
        cache: false,
        data: JSON.stringify(reqData),
        url: arangoHelper.databaseUrl('/_api/view/' + encodeURIComponent(this.get('name')) + '/properties'),
        headers: {
          'x-arango-async': 'store'
        },
        contentType: 'application/json',
        processData: false,
        success: function success(data, textStatus, xhr) {
          if (xhr.getResponseHeader('x-arango-async-id')) {
            window.arangoHelper.addAardvarkJob({
              id: xhr.getResponseHeader('x-arango-async-id'),
              type: 'view',
              desc: 'Patching View',
              collection: self.get('name')
            });
            callback(false, data, true);
          } else {
            callback(true, data);
          }

          callback(false, data, false);
        }
      });
    },
    deleteView: function deleteView(callback) {
      var self = this;
      $.ajax({
        cache: false,
        type: 'DELETE',
        url: arangoHelper.databaseUrl('/_api/view/' + encodeURIComponent(self.get('name'))),
        headers: {
          'x-arango-async': 'store'
        },
        success: function success(data, textStatus, xhr) {
          if (xhr.getResponseHeader('x-arango-async-id')) {
            window.arangoHelper.addAardvarkJob({
              id: xhr.getResponseHeader('x-arango-async-id'),
              type: 'view',
              desc: 'Removing View',
              collection: self.get('name')
            });
            callback(false, data);
          } else {
            callback(true, data);
          }
        },
        error: function error(data) {
          callback(true, data);
        }
      });
    },
    renameView: function renameView(name, callback) {
      var self = this;
      $.ajax({
        cache: false,
        type: 'PUT',
        url: arangoHelper.databaseUrl('/_api/view/' + encodeURIComponent(self.get('name')) + '/rename'),
        data: JSON.stringify({
          name: name
        }),
        contentType: 'application/json',
        processData: false,
        success: function success() {
          self.set('name', name);
          callback(false);
        },
        error: function error(data) {
          callback(true, data);
        }
      });
    }
  });
})();