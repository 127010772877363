/* jshint unused: false */
// eslint-disable-next-line no-unused-vars

/* global window, $, Backbone, document, d3, ReactDOM, React */

/* global arangoHelper, btoa, atob, _, frontendConfig */
(function () {
  'use strict';

  var isCurrentCoordinator = false;
  window.Router = Backbone.Router.extend({
    toUpdate: [],
    dbServers: [],
    isCluster: undefined,
    foxxApiEnabled: undefined,
    statisticsInAllDatabases: undefined,
    lastRoute: undefined,
    maxNumberOfMoveShards: undefined,
    routes: {
      '': 'cluster',
      'dashboard': 'dashboard',
      'replication': 'replication',
      'replication/applier/:endpoint/:database': 'applier',
      'collections': 'collections',
      'analyzers': 'analyzers',
      'new': 'newCollection',
      'login': 'login',
      'collection/:colid/documents/:pageid': 'documents',
      'cIndices/:colname': 'cIndices',
      'cSettings/:colname': 'cSettings',
      'cSchema/:colname': 'cSchema',
      'cInfo/:colname': 'cInfo',
      'collection/:colid/:docid': 'document',
      'queries': 'query',
      'databases': 'databases',
      'settings': 'databases',
      'services': 'applications',
      'services/install': 'installService',
      'services/install/new': 'installNewService',
      'services/install/github': 'installGitHubService',
      'services/install/upload': 'installUploadService',
      'services/install/remote': 'installUrlService',
      'service/:mount': 'applicationDetail',
      'store/:name': 'storeDetail',
      'graphs': 'graphManagement',
      'graphs/:name': 'showGraph',
      'metrics': 'metrics',
      'users': 'userManagement',
      'user/:name': 'userView',
      'user/:name/permission': 'userPermission',
      'userProfile': 'userProfile',
      'cluster': 'cluster',
      'nodes': 'nodes',
      'shards': 'shards',
      'rebalanceShards': 'rebalanceShards',
      'maintenance': 'maintenance',
      'distribution': 'distribution',
      'node/:name': 'node',
      'nodeInfo/:id': 'nodeInfo',
      'logs': 'logger',
      'helpus': 'helpUs',
      'views': 'views',
      'view/:name': 'view',
      'graph/:name': 'graph',
      'graph/:name/settings': 'graphSettings',
      'support': 'support'
    },
    execute: function execute(callback, args) {
      if (this.lastRoute === '#queries') {
        // cleanup input editors
        this.queryView.removeInputEditors(); // cleanup old canvas elements

        this.queryView.cleanupGraphs(); // cleanup old ace instances

        this.queryView.removeResults();
      }

      if (this.lastRoute) {
        // service replace logic
        var replaceUrlFirst = this.lastRoute.split('/')[0];
        var replaceUrlSecond = this.lastRoute.split('/')[1];
        var replaceUrlThird = this.lastRoute.split('/')[2];

        if (replaceUrlFirst !== '#service') {
          if (window.App.replaceApp) {
            if (replaceUrlSecond !== 'install' && replaceUrlThird) {
              window.App.replaceApp = false; // console.log('set replace to false!');
            }
          } else {
            // console.log('set replace to false!');
            window.App.replaceApp = false;
          }
        }

        if (this.lastRoute.substr(0, 11) === '#collection' && this.lastRoute.split('/').length === 3) {
          this.documentView.cleanupEditor();
        }

        if (this.lastRoute === '#dasboard' || window.location.hash.substr(0, 5) === '#node') {
          // dom graph cleanup
          d3.selectAll('svg > *').remove();
        }

        if (this.lastRoute === '#logger') {
          if (this.loggerView.logLevelView) {
            this.loggerView.logLevelView.remove();
          }

          if (this.loggerView.logTopicView) {
            this.loggerView.logTopicView.remove();
          }
        }

        if (this.lastRoute === '#shards') {
          if (this.shardsView) {
            this.shardsView.remove();
          }
        } // react unmounting


        ReactDOM.unmountComponentAtNode(document.getElementById('content'));
      }

      this.lastRoute = window.location.hash; // this function executes before every route call

      $('#subNavigationBar .breadcrumb').html('');
      $('#subNavigationBar .bottom').html('');
      $('#loadingScreen').hide();
      $('#content').show();

      if (callback) {
        callback.apply(this, args);
      }

      if (this.lastRoute === '#services') {
        window.App.replaceApp = false;
      }

      if (this.graphViewer) {
        if (this.graphViewer.graphSettingsView) {
          this.graphViewer.graphSettingsView.hide();
        }
      }

      if (this.queryView) {
        if (this.queryView.graphViewer) {
          if (this.queryView.graphViewer.graphSettingsView) {
            this.queryView.graphViewer.graphSettingsView.hide();
          }
        }
      }
    },
    listenerFunctions: {},
    listener: function listener(event) {
      _.each(window.App.listenerFunctions, function (func, key) {
        void key;
        func(event);
      });
    },
    checkUser: function checkUser() {
      var self = this;

      if (window.location.hash === '#login') {
        return;
      }

      var startInit = function () {
        this.initOnce(); // show hidden by default divs

        $('.bodyWrapper').show();
        $('.navbar').show();
      }.bind(this);

      var callback = function (error, user) {
        if (frontendConfig.authenticationEnabled) {
          self.currentUser = user;

          if (error || user === null) {
            if (window.location.hash !== '#login') {
              this.navigate('login', {
                trigger: true
              });
            }
          } else {
            startInit();
          }
        } else {
          startInit();
        }
      }.bind(this);

      if (frontendConfig.authenticationEnabled) {
        this.userCollection.whoAmI(callback);
      } else {
        this.initOnce(); // show hidden by default divs

        $('.bodyWrapper').show();
        $('.navbar').show();
      }
    },
    initialize: function initialize() {
      var self = this;
      this.init = new Promise(function (resolve, reject) {
        self.initSucceeded = resolve;
        self.initFailed = reject;
      }); // check frontend config for global conf settings

      this.isCluster = frontendConfig.isCluster;

      if (typeof frontendConfig.foxxApiEnabled === 'boolean') {
        this.foxxApiEnabled = frontendConfig.foxxApiEnabled;
      }

      if (typeof frontendConfig.statisticsInAllDatabases === 'boolean') {
        this.statisticsInAllDatabases = frontendConfig.statisticsInAllDatabases;
      }

      this.maxNumberOfMoveShards = frontendConfig.maxNumberOfMoveShards;
      document.addEventListener('keyup', this.listener, false); // This should be the only global object

      window.modalView = new window.ModalView(); // foxxes

      this.foxxList = new window.FoxxCollection(); // foxx repository

      this.foxxRepo = new window.FoxxRepository();

      if (frontendConfig.foxxStoreEnabled) {
        this.foxxRepo.fetch({
          success: function success() {
            if (self.serviceInstallView) {
              self.serviceInstallView.collection = self.foxxRepo;
            }
          }
        });
      }

      window.progressView = new window.ProgressView();
      this.userCollection = new window.ArangoUsers();
      this.initOnce = _.once(function () {
        var callback = function callback(error, isCoordinator) {
          if (isCoordinator === true) {
            isCurrentCoordinator = true;
            self.coordinatorCollection.fetch({
              success: function success() {
                self.fetchDBS();
              }
            });
          }

          if (error) {
            console.log(error);
          }
        };

        window.isCoordinator(callback);

        if (frontendConfig.isCluster === false) {
          this.initSucceeded(true);
        }

        this.arangoDatabase = new window.ArangoDatabase();
        this.currentDB = new window.CurrentDatabase();
        this.arangoCollectionsStore = new window.ArangoCollections();
        this.arangoDocumentStore = new window.ArangoDocument();
        this.arangoViewsStore = new window.ArangoViews(); // Cluster

        this.coordinatorCollection = new window.ClusterCoordinators();
        window.spotlightView = new window.SpotlightView({
          collection: this.arangoCollectionsStore
        });
        arangoHelper.setDocumentStore(this.arangoDocumentStore);
        this.arangoCollectionsStore.fetch({
          cache: false
        });
        this.footerView = new window.FooterView({
          collection: self.coordinatorCollection
        });
        this.notificationList = new window.NotificationCollection();
        this.currentDB.fetch({
          cache: false,
          success: function success() {
            self.naviView = new window.NavigationView({
              database: self.arangoDatabase,
              currentDB: self.currentDB,
              notificationCollection: self.notificationList,
              userCollection: self.userCollection,
              isCluster: self.isCluster,
              foxxApiEnabled: self.foxxApiEnabled,
              statisticsInAllDatabases: self.statisticsInAllDatabases
            });
            self.naviView.render();
          }
        });
        this.queryCollection = new window.ArangoQueries();
        this.footerView.render();
        window.checkVersion();
        this.userConfig = new window.UserConfig({
          ldapEnabled: frontendConfig.ldapEnabled
        });
        this.userConfig.fetch();
        this.documentsView = new window.DocumentsView({
          collection: new window.ArangoDocuments(),
          documentStore: this.arangoDocumentStore,
          collectionsStore: this.arangoCollectionsStore
        });
        arangoHelper.initSigma();
      }).bind(this);
      $(window).on('resize', function () {
        self.handleResize();
      });
    },
    analyzers: function analyzers() {
      this.checkUser();
      this.init.then(function () {
        return ReactDOM.render(React.createElement(window.AnalyzersReactView), document.getElementById('content'));
      });
    },
    cluster: function cluster() {
      var _this = this;

      this.checkUser();
      this.init.then(function () {
        if (_this.isCluster && frontendConfig.clusterApiJwtPolicy === 'jwt-all') {
          // no privileges to use cluster/nodes from the web UI
          _this.routes[''] = 'collections';

          _this.navigate('#collections', {
            trigger: true
          });

          return;
        }

        if (!_this.isCluster) {
          if (_this.currentDB.get('name') === '_system') {
            _this.routes[''] = 'dashboard';

            _this.navigate('#dashboard', {
              trigger: true
            });
          } else {
            _this.routes[''] = 'collections';

            _this.navigate('#collections', {
              trigger: true
            });
          }

          return;
        }

        if (_this.currentDB.get('name') !== '_system' && !_this.statisticsInAllDatabases) {
          _this.navigate('#nodes', {
            trigger: true
          });

          return;
        }

        if (!_this.clusterView) {
          _this.clusterView = new window.ClusterView({
            coordinators: _this.coordinatorCollection,
            dbServers: _this.dbServers
          });
        }

        _this.clusterView.render();
      });
    },
    node: function node(id) {
      var _this2 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this2.isCluster && frontendConfig.clusterApiJwtPolicy === 'jwt-all') {
          // no privileges to use cluster/nodes from the web UI
          _this2.routes[''] = 'collections';

          _this2.navigate('#collections', {
            trigger: true
          });

          return;
        }

        if (_this2.isCluster === false) {
          _this2.routes[''] = 'dashboard';

          _this2.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        if (_this2.nodeView) {
          _this2.nodeView.remove();
        }

        _this2.nodeView = new window.NodeView({
          coordid: id,
          coordinators: _this2.coordinatorCollection,
          dbServers: _this2.dbServers
        });

        _this2.nodeView.render();
      });
    },
    shards: function shards() {
      var _this3 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this3.isCluster === false) {
          _this3.routes[''] = 'dashboard';

          _this3.navigate('#dashboard', {
            trigger: true
          });

          return;
        } // TODO re-enable React View, for now use old view:
        // ReactDOM.render(React.createElement(window.ShardsReactView),
        //   document.getElementById('content'));
        // Below code needs to be removed then again.


        if (_this3.shardsView) {
          _this3.shardsView.remove();
        }

        _this3.shardsView = new window.ShardsView({
          dbServers: _this3.dbServers
        });

        _this3.shardsView.render();
      });
    },
    rebalanceShards: function rebalanceShards() {
      var _this4 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this4.isCluster === false || isCurrentCoordinator === false || _this4.maxNumberOfMoveShards === 0) {
          _this4.routes[''] = 'dashboard';

          _this4.navigate('#dashboard', {
            trigger: true
          });

          return;
        } // this below is for when Rebalance Shards tab is not clickable, but user enters it through its URL
        else if (_this4.userCollection.authOptions.ro) {
          // if user can't edit the database,
          // it goes back to the Overview page
          _this4.routes[''] = 'nodes';

          _this4.navigate('#nodes', {
            trigger: true
          });

          return;
        }

        if (_this4.rebalanceShardsView) {
          _this4.rebalanceShardsView.remove();
        }

        _this4.rebalanceShardsView = new window.RebalanceShardsView({
          maxNumberOfMoveShards: _this4.maxNumberOfMoveShards
        });

        _this4.rebalanceShardsView.render();
      });
    },
    distribution: function distribution() {
      var _this5 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this5.currentDB.get('name') !== '_system') {
          if (!_this5.isCluster) {
            _this5.routes[''] = 'dashboard';

            _this5.navigate('#dashboard', {
              trigger: true
            });
          } else {
            _this5.routes[''] = 'cluster';

            _this5.navigate('#cluster', {
              trigger: true
            });
          }

          return;
        }

        if (_this5.shardDistributionView) {
          _this5.shardDistributionView.remove();
        }

        _this5.shardDistributionView = new window.ShardDistributionView({});

        _this5.shardDistributionView.render();
      });
    },
    maintenance: function maintenance() {
      var _this6 = this;

      this.checkUser();
      this.init.then(function () {
        if (frontendConfig.showMaintenanceStatus === false || _this6.currentDB.get('name') !== '_system') {
          if (!_this6.isCluster) {
            _this6.routes[''] = 'dashboard';

            _this6.navigate('#dashboard', {
              trigger: true
            });
          } else {
            _this6.routes[''] = 'cluster';

            _this6.navigate('#cluster', {
              trigger: true
            });
          }

          return;
        }

        if (_this6.maintenanceView) {
          _this6.maintenanceView.remove();
        }

        _this6.maintenanceView = new window.MaintenanceView({});

        _this6.maintenanceView.render();
      });
    },
    nodes: function nodes() {
      var _this7 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this7.isCluster === false) {
          _this7.routes[''] = 'dashboard';

          _this7.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        if (_this7.nodesView) {
          _this7.nodesView.remove();
        }

        _this7.nodesView = new window.NodesView({});

        _this7.nodesView.render();
      });
    },
    cNodes: function cNodes() {
      var _this8 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this8.isCluster === false) {
          _this8.routes[''] = 'dashboard';

          _this8.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        _this8.nodesView = new window.NodesView({
          coordinators: _this8.coordinatorCollection,
          dbServers: _this8.dbServers[0],
          toRender: 'coordinator'
        });

        _this8.nodesView.render();
      });
    },
    dNodes: function dNodes() {
      var _this9 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this9.isCluster === false) {
          _this9.routes[''] = 'dashboard';

          _this9.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        if (_this9.dbServers.length === 0) {
          _this9.navigate('#cNodes', {
            trigger: true
          });

          return;
        }

        _this9.nodesView = new window.NodesView({
          coordinators: _this9.coordinatorCollection,
          dbServers: _this9.dbServers[0],
          toRender: 'dbserver'
        });

        _this9.nodesView.render();
      });
    },
    sNodes: function sNodes() {
      var _this10 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this10.isCluster === false) {
          _this10.routes[''] = 'dashboard';

          _this10.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        _this10.scaleView = new window.ScaleView({
          coordinators: _this10.coordinatorCollection,
          dbServers: _this10.dbServers[0]
        });

        _this10.scaleView.render();
      });
    },
    addAuth: function addAuth(xhr) {
      var u = this.clusterPlan.get('user');

      if (!u) {
        xhr.abort();

        if (!this.isCheckingUser) {
          this.requestAuth();
        }

        return;
      }

      var user = u.name;
      var pass = u.passwd;
      var token = user.concat(':', pass);
      xhr.setRequestHeader('Authorization', 'Basic ' + btoa(token));
    },
    logger: function logger() {
      var _this11 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this11.loggerView) {
          _this11.loggerView.remove();
        }

        var co = new window.ArangoLogs({
          upto: true,
          loglevel: 4
        });
        _this11.loggerView = new window.LoggerView({
          collection: co
        });

        _this11.loggerView.render(true);
      });
    },
    applicationDetail: function applicationDetail(mount) {
      var _this12 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this12.foxxApiEnabled) {
          _this12.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        var callback = function () {
          if (this.hasOwnProperty('applicationDetailView')) {
            this.applicationDetailView.remove();
          }

          this.applicationDetailView = new window.ApplicationDetailView({
            model: this.foxxList.get(decodeURIComponent(mount))
          });
          this.applicationDetailView.model = this.foxxList.get(decodeURIComponent(mount));
          this.applicationDetailView.render('swagger');
        }.bind(_this12);

        if (_this12.foxxList.length === 0) {
          _this12.foxxList.fetch({
            cache: false,
            success: function success() {
              callback();
            }
          });
        } else {
          callback();
        }
      });
    },
    storeDetail: function storeDetail(mount) {
      var _this13 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this13.foxxApiEnabled) {
          _this13.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        var callback = function () {
          if (this.hasOwnProperty('storeDetailView')) {
            this.storeDetailView.remove();
          }

          this.storeDetailView = new window.StoreDetailView({
            model: this.foxxRepo.get(decodeURIComponent(mount)),
            collection: this.foxxList
          });
          this.storeDetailView.model = this.foxxRepo.get(decodeURIComponent(mount));
          this.storeDetailView.render();
        }.bind(_this13);

        if (_this13.foxxRepo.length === 0) {
          _this13.foxxRepo.fetch({
            cache: false,
            success: function success() {
              callback();
            }
          });
        } else {
          callback();
        }
      });
    },
    login: function login() {
      var callback = function (error, user) {
        if (!this.loginView) {
          this.loginView = new window.LoginView({
            collection: this.userCollection
          });
        }

        if (error || user === null || user === undefined) {
          this.loginView.render();
        } else {
          this.loginView.render(true);
        }
      }.bind(this);

      this.userCollection.whoAmI(callback);
    },
    collections: function collections() {
      var _this14 = this;

      this.checkUser();
      this.init.then(function () {
        var self = _this14;

        if (_this14.collectionsView) {
          _this14.collectionsView.remove();
        }

        _this14.collectionsView = new window.CollectionsView({
          collection: _this14.arangoCollectionsStore
        });

        _this14.arangoCollectionsStore.fetch({
          cache: false,
          success: function success() {
            self.collectionsView.render();
          }
        });
      });
    },
    cIndices: function cIndices(colname) {
      var _this15 = this;

      var self = this;
      this.checkUser();
      this.init.then(function () {
        _this15.arangoCollectionsStore.fetch({
          cache: false,
          success: function success() {
            if (self.indicesView) {
              self.indicesView.remove();
            }

            self.indicesView = new window.IndicesView({
              collectionName: colname,
              collection: self.arangoCollectionsStore.findWhere({
                name: colname
              })
            });
            self.indicesView.render();
          }
        });
      });
    },
    cSettings: function cSettings(colname) {
      var _this16 = this;

      var self = this;
      this.checkUser();
      this.init.then(function () {
        _this16.arangoCollectionsStore.fetch({
          cache: false,
          success: function success() {
            self.settingsView = new window.SettingsView({
              collectionName: colname,
              collection: self.arangoCollectionsStore.findWhere({
                name: colname
              })
            });
            self.settingsView.render();
          }
        });
      });
    },
    cSchema: function cSchema(colname) {
      var _this17 = this;

      var self = this;
      this.checkUser();
      this.init.then(function () {
        _this17.arangoCollectionsStore.fetch({
          cache: false,
          success: function success() {
            self.settingsView = new window.ValidationView({
              collectionName: colname,
              collection: self.arangoCollectionsStore.findWhere({
                name: colname
              })
            });
            self.settingsView.render();
          }
        });
      });
    },
    cInfo: function cInfo(colname) {
      var _this18 = this;

      var self = this;
      this.checkUser();
      this.init.then(function () {
        _this18.arangoCollectionsStore.fetch({
          cache: false,
          success: function success() {
            self.infoView = new window.InfoView({
              collectionName: colname,
              collection: self.arangoCollectionsStore.findWhere({
                name: colname
              })
            });
            self.infoView.render();
          }
        });
      });
    },
    documents: function documents(colid, pageid) {
      var _this19 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this19.documentsView) {
          _this19.documentsView.unbindEvents();
        }

        if (!_this19.documentsView) {
          _this19.documentsView = new window.DocumentsView({
            collection: new window.ArangoDocuments(),
            documentStore: _this19.arangoDocumentStore,
            collectionsStore: _this19.arangoCollectionsStore
          });
        }

        _this19.documentsView.setCollectionId(colid, pageid);

        _this19.documentsView.render();

        _this19.documentsView.delegateEvents();
      });
    },
    document: function document(colid) {
      var _this20 = this;

      this.checkUser();
      this.init.then(function () {
        var mode;

        if (_this20.documentView) {
          if (_this20.documentView.defaultMode) {
            mode = _this20.documentView.defaultMode;
          }

          _this20.documentView.remove();
        }

        _this20.documentView = new window.DocumentView({
          collection: _this20.arangoDocumentStore
        });
        _this20.documentView.colid = colid;
        _this20.documentView.defaultMode = mode;
        var doc = window.location.hash.split('/')[2];
        doc = decodeURIComponent(doc);
        _this20.documentView.docid = doc;

        _this20.documentView.render();

        var callback = function (error, type) {
          void type;

          if (!error) {
            this.documentView.setType();
          } else {
            this.documentView.renderNotFound(colid, true);
          }
        }.bind(_this20);

        arangoHelper.collectionApiType(colid, null, callback);
      });
    },
    query: function query() {
      var _this21 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this21.queryView) {
          _this21.queryView = new window.QueryView({
            collection: _this21.queryCollection
          });
        }

        _this21.queryView.render();
      });
    },
    graph: function graph(name) {
      var _this22 = this;

      this.checkUser();
      this.init.then(function () {
        // TODO better manage mechanism for both gv's
        if (_this22.graphViewer) {
          if (_this22.graphViewer.graphSettingsView) {
            _this22.graphViewer.graphSettingsView.remove();
          }

          _this22.graphViewer.killCurrentGraph();

          _this22.graphViewer.unbind();

          _this22.graphViewer.remove();
        }

        _this22.graphViewer = new window.GraphViewer({
          name: name,
          documentStore: _this22.arangoDocumentStore,
          collection: new window.GraphCollection(),
          userConfig: _this22.userConfig
        });

        _this22.graphViewer.render();
      });
    },
    graphSettings: function graphSettings(name) {
      var _this23 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this23.graphSettingsView) {
          _this23.graphSettingsView.remove();
        }

        _this23.graphSettingsView = new window.GraphSettingsView({
          name: name,
          userConfig: _this23.userConfig
        });

        _this23.graphSettingsView.render();
      });
    },
    helpUs: function helpUs() {
      var _this24 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this24.testView) {
          _this24.helpUsView = new window.HelpUsView({});
        }

        _this24.helpUsView.render();
      });
    },
    support: function support() {
      var _this25 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this25.testView) {
          _this25.supportView = new window.SupportView({});
        }

        _this25.supportView.render();
      });
    },
    queryManagement: function queryManagement() {
      var _this26 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this26.queryManagementView) {
          _this26.queryManagementView.remove();
        }

        _this26.queryManagementView = new window.QueryManagementView({
          collection: undefined
        });

        _this26.queryManagementView.render();
      });
    },
    databases: function databases() {
      var _this27 = this;

      this.checkUser();
      this.init.then(function () {
        var callback = function (error) {
          if (error) {
            arangoHelper.arangoError('DB', 'Could not get list of allowed databases');
            this.navigate('#', {
              trigger: true
            });
            $('#databaseNavi').css('display', 'none');
            $('#databaseNaviSelect').css('display', 'none');
          } else {
            if (this.databaseView) {
              // cleanup events and view
              this.databaseView.remove();
            }

            this.databaseView = new window.DatabaseView({
              users: this.userCollection,
              collection: this.arangoDatabase
            });
            this.databaseView.render();
          }
        }.bind(_this27);

        arangoHelper.databaseAllowed(callback);
      });
    },
    dashboard: function dashboard() {
      var _this28 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this28.dashboardView === undefined) {
          _this28.dashboardView = new window.DashboardView({
            dygraphConfig: window.dygraphConfig,
            database: _this28.arangoDatabase
          });
        }

        _this28.dashboardView.render();
      });
    },
    replication: function replication() {
      var _this29 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this29.replicationView) {
          // this.replicationView.remove();
          _this29.replicationView = new window.ReplicationView({});
        }

        _this29.replicationView.render();
      });
    },
    applier: function applier(endpoint, database) {
      var _this30 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this30.applierView === undefined) {
          _this30.applierView = new window.ApplierView({});
        }

        _this30.applierView.endpoint = atob(endpoint);
        _this30.applierView.database = atob(database);

        _this30.applierView.render();
      });
    },
    graphManagement: function graphManagement() {
      var _this31 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this31.graphManagementView) {
          _this31.graphManagementView.undelegateEvents();
        }

        _this31.graphManagementView = new window.GraphManagementView({
          collection: new window.GraphCollection(),
          collectionCollection: _this31.arangoCollectionsStore
        });

        _this31.graphManagementView.render();
      });
    },
    showGraph: function showGraph(name) {
      var _this32 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this32.graphManagementView) {
          _this32.graphManagementView = new window.GraphManagementView({
            collection: new window.GraphCollection(),
            collectionCollection: _this32.arangoCollectionsStore
          });

          _this32.graphManagementView.render(name, true);
        } else {
          _this32.graphManagementView.loadGraphViewer(name);
        }
      });
    },
    applications: function applications() {
      var _this33 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this33.foxxApiEnabled) {
          _this33.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        if (_this33.applicationsView === undefined) {
          _this33.applicationsView = new window.ApplicationsView({
            collection: _this33.foxxList
          });
        }

        _this33.applicationsView.reload();
      });
    },
    installService: function installService() {
      var _this34 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this34.foxxApiEnabled) {
          _this34.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        if (!frontendConfig.foxxStoreEnabled) {
          _this34.navigate('#services/install/upload', {
            trigger: true
          });

          return;
        }

        window.modalView.clearValidators();

        if (_this34.serviceInstallView) {
          _this34.serviceInstallView.remove();
        }

        _this34.serviceInstallView = new window.ServiceInstallView({
          collection: _this34.foxxRepo,
          functionsCollection: _this34.foxxList
        });

        _this34.serviceInstallView.render();
      });
    },
    installNewService: function installNewService() {
      var _this35 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this35.foxxApiEnabled) {
          _this35.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        window.modalView.clearValidators();

        if (_this35.serviceNewView) {
          _this35.serviceNewView.remove();
        }

        _this35.serviceNewView = new window.ServiceInstallNewView({
          collection: _this35.foxxList
        });

        _this35.serviceNewView.render();
      });
    },
    installGitHubService: function installGitHubService() {
      var _this36 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this36.foxxApiEnabled) {
          _this36.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        window.modalView.clearValidators();

        if (_this36.serviceGitHubView) {
          _this36.serviceGitHubView.remove();
        }

        _this36.serviceGitHubView = new window.ServiceInstallGitHubView({
          collection: _this36.foxxList
        });

        _this36.serviceGitHubView.render();
      });
    },
    installUrlService: function installUrlService() {
      var _this37 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this37.foxxApiEnabled) {
          _this37.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        if (!frontendConfig.foxxAllowInstallFromRemote) {
          _this37.navigate('#services/install/upload', {
            trigger: true
          });

          return;
        }

        window.modalView.clearValidators();

        if (_this37.serviceUrlView) {
          _this37.serviceUrlView.remove();
        }

        _this37.serviceUrlView = new window.ServiceInstallUrlView({
          collection: _this37.foxxList
        });

        _this37.serviceUrlView.render();
      });
    },
    installUploadService: function installUploadService() {
      var _this38 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this38.foxxApiEnabled) {
          _this38.navigate('#dashboard', {
            trigger: true
          });

          return;
        }

        window.modalView.clearValidators();

        if (_this38.serviceUploadView) {
          _this38.serviceUploadView.remove();
        }

        _this38.serviceUploadView = new window.ServiceInstallUploadView({
          collection: _this38.foxxList
        });

        _this38.serviceUploadView.render();
      });
    },
    handleSelectDatabase: function handleSelectDatabase() {
      var _this39 = this;

      this.checkUser();
      this.init.then(function () {
        return _this39.naviView.handleSelectDatabase();
      });
    },
    handleResize: function handleResize() {
      if (this.dashboardView) {
        this.dashboardView.resize();
      }

      if (this.graphManagementView && Backbone.history.getFragment() === 'graphs') {
        this.graphManagementView.handleResize($('#content').width());
      }

      if (this.queryView && Backbone.history.getFragment() === 'queries') {
        this.queryView.resize();
      }

      if (this.naviView) {
        this.naviView.resize();
      }

      if (this.graphViewer && Backbone.history.getFragment().indexOf('graph') > -1) {
        this.graphViewer.resize();
      }

      if (this.documentsView && Backbone.history.getFragment().indexOf('documents') > -1) {
        this.documentsView.resize();
      }

      if (this.documentView && Backbone.history.getFragment().indexOf('collection') > -1) {
        this.documentView.resize();
      }

      if (this.validationView && Backbone.history.getFragment().indexOf('cSchema') > -1) {
        this.validationView.resize();
      }
    },
    userPermission: function userPermission(name) {
      var _this40 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this40.userPermissionView) {
          _this40.userPermissionView.remove();
        }

        _this40.userPermissionView = new window.UserPermissionView({
          collection: _this40.userCollection,
          databases: _this40.arangoDatabase,
          username: name
        });

        _this40.userPermissionView.render();
      });
    },
    userView: function userView(name) {
      var _this41 = this;

      this.checkUser();
      this.init.then(function () {
        _this41.userView = new window.UserView({
          collection: _this41.userCollection,
          username: name
        });

        _this41.userView.render();
      });
    },
    userManagement: function userManagement() {
      var _this42 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this42.userManagementView) {
          _this42.userManagementView.remove();
        }

        _this42.userManagementView = new window.UserManagementView({
          collection: _this42.userCollection
        });

        _this42.userManagementView.render();
      });
    },
    userProfile: function userProfile() {
      var _this43 = this;

      this.checkUser();
      this.init.then(function () {
        if (!_this43.userManagementView) {
          _this43.userManagementView = new window.UserManagementView({
            collection: _this43.userCollection
          });
        }

        _this43.userManagementView.render(true);
      });
    },
    view: function view(name) {
      var _this44 = this;

      var self = this;
      this.checkUser();
      this.init.then(function () {
        if (_this44.viewView) {
          _this44.viewView.remove();
        }

        _this44.arangoViewsStore.fetch({
          success: function success() {
            self.viewView = new window.ViewView({
              model: self.arangoViewsStore.get(name),
              name: name
            });
            self.viewView.render();
          }
        });
      });
    },
    views: function views() {
      var _this45 = this;

      this.checkUser();
      this.init.then(function () {
        if (_this45.viewsView) {
          _this45.viewsView.remove();
        }

        _this45.viewsView = new window.ViewsView({
          collection: _this45.arangoViewsStore
        });

        _this45.viewsView.render();
      });
    },
    fetchDBS: function fetchDBS(callback) {
      var self = this;
      var cb = false;
      this.coordinatorCollection.each(function (coordinator) {
        self.dbServers.push(new window.ClusterServers([], {
          host: coordinator.get('address')
        }));
      });
      this.initSucceeded(true);

      _.each(this.dbServers, function (dbservers) {
        dbservers.fetch({
          success: function success() {
            if (cb === false) {
              if (callback) {
                callback();
                cb = true;
              }
            }
          }
        });
      });
    },
    getNewRoute: function getNewRoute(host) {
      return 'http://' + host;
    },
    registerForUpdate: function registerForUpdate(o) {
      this.toUpdate.push(o);
      o.updateUrl();
    }
  });
})();