module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="paddingBox">\n  <div class="borderBox"></div>\n  <i class="collection-type-icon fa '+
((__t=( model.get('picture') ))==null?'':__t)+
'"></i>\n  <div class="tileBadge">\n    <span>\n    ';
 if (model.get('desc')) { 
__p+='\n      <div class="corneredBadge inProgress">\n        '+
((__t=( model.get('desc') ))==null?'':__t)+
'\n      </div>\n    ';
 } else if (model.get('status') === 'corrupted') { 
__p+='\n      <div class="corneredBadge '+
((__t=( model.get('status') ))==null?'':__t)+
'">\n        '+
((__t=( model.get('status') ))==null?'':__t)+
'\n      </div>\n    ';
 } 
__p+='\n    </span>\n  </div>\n\n  ';
 if(model.get('lockType') === "index") { 
__p+='\n    <!-- <progress max="100" value="80"></progress>-->\n    <h5 class="collectionName">'+
((__t=( model.get('name') ))==null?'':__t)+
'</h5>\n  ';
 } else { 
__p+='\n    <h5 class="collectionName">'+
((__t=( model.get('name') ))==null?'':__t)+
'</h5>\n  ';
 } 
__p+='\n</div>\n';
}
return __p;
};
