module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var figuresData = content.figures || {};
var revision = content.revision;
var clusterData = content.cluster || {};

if (!figuresData.figures) {
  figuresData.figures = {};
}

__p+='\n\n<div>\n  <div\n    style="color: #717d90; font-weight: 600; font-size: 12.5pt; padding: 10px; border-bottom: 1px solid rgba(0, 0, 0, .3);">\n    Properties\n  </div>\n  <table id="collectionInfoTable" class="arango-table">\n    <tr id="collectionSyncBox">\n      <th class="collectionInfoTh2">Wait for sync:</th>\n      <th class="collectionInfoTh">\n        <div id="show-collection-sync" class="modal-text">\n          '+
((__t=( figuresData.waitForSync ))==null?'':__t)+
'\n        </div>\n      </th>\n      <th class="tooltipInfoTh">\n        <div>\n                <span class="modalTooltips arangoicon icon_arangodb_info"\n                      title="Synchronize to disk before returning from a create or update of a document.">\n                </span>\n        </div>\n      </th>\n    </tr>\n\n    <tr>\n      <th class="collectionInfoTh2">ID:</th>\n      <th class="collectionInfoTh">\n        <div id="show-collection-id" class="modal-text">\n          '+
((__t=( content.model.get("id") ))==null?'':__t)+
'\n        </div>\n      <th>\n    </tr>\n\n    <tr id="collectionRevBox">\n      <th class="collectionInfoTh2">Revision:</th>\n      <th class="collectionInfoTh">\n        <div id="show-collection-rev" class="modal-text">\n          '+
((__t=( revision.revision ))==null?'':__t)+
'\n        </div>\n      </th>\n      <th class="collectionInfoTh">\n      </th>\n    </tr>\n\n    <tr>\n      <th class="collectionInfoTh2">Type:</th>\n      <th class="collectionInfoTh">\n        <div class="modal-text">'+
((__t=( content.model.get("type") ))==null?'':__t)+
'</div>\n      </th>\n      <th class="collectionInfoTh">\n      </th>\n    </tr>\n\n    <tr>\n      <th class="collectionInfoTh2">Status:</th>\n      <th class="collectionInfoTh">\n        <div class="modal-text">'+
((__t=( content.model.get("status") ))==null?'':__t)+
'</div>\n      </th>\n      <th class="collectionInfoTh">\n      </th>\n    </tr>\n\n    ';
 if (figuresData.hasOwnProperty('cacheEnabled') && figuresData.cacheEnabled) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">Cache enabled:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.cacheEnabled ))==null?'':__t)+
'</div>\n        </th>\n        <th class="collectionInfoTh">\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.hasOwnProperty('isSystem')) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">System collection:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.isSystem ))==null?'':__t)+
'</div>\n        </th>\n        <th class="collectionInfoTh">\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.hasOwnProperty('syncByRevision')) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">Uses revision trees:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.syncByRevision ))==null?'':__t)+
'</div>\n        </th>\n        <th class="collectionInfoTh">\n        </th>\n      </tr>\n    ';
 } 
__p+='\n  </table>\n\n  ';
 if (figuresData.hasOwnProperty('figures')) { 
__p+='\n    <div\n      style="color: #717d90; font-weight: 600; font-size: 12.5pt; padding: 10px; border-bottom: 1px solid rgba(0, 0, 0, .3); border-top: 1px solid rgba(0, 0, 0, .3)">\n      Figures\n    </div>\n    <table id="collectionFiguresTable" class="arango-table">\n\n      ';
 if (figuresData.figures.hasOwnProperty('documentsSize')) { 
__p+='\n        <tr>\n          <th class="collectionInfoTh2">Estimated documents size:</th>\n          <th class="collectionInfoTh">\n            <div class="modal-text">'+
((__t=( prettyBytes(figuresData.figures.documentsSize) ))==null?'':__t)+
'</div>\n          </th>\n          <th class="tooltipInfoTh">\n            <div>\n                <span class="modalTooltips arangoicon icon_arangodb_info"\n                      title="The approximate cumulative size of all documents in this collection (on the leader). This is an estimate provided by the storage engine and may not be accurate.">\n                </span>\n            </div>\n          </th>\n        </tr>\n      ';
 } 
__p+='\n\n      ';
 if (figuresData.figures.hasOwnProperty('indexes')) { 
__p+='\n\n        ';
 if (figuresData.figures.indexes.hasOwnProperty('count')) { 
__p+='\n          ';
 let amountOfIndexes = figuresData.figures.indexes.count; 
__p+='\n          <tr>\n            <th class="collectionInfoTh2">Number of indexes:</th>\n            <th class="collectionInfoTh">\n              <div class="modal-text">'+
((__t=( amountOfIndexes ))==null?'':__t)+
'</div>\n            </th>\n            <th class="tooltipInfoTh">\n            </th>\n          </tr>\n\n          ';
 if (amountOfIndexes > 0 && figuresData.figures.indexes.hasOwnProperty('size')) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Estimated size of indexes:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">'+
((__t=( prettyBytes(figuresData.figures.indexes.size) ))==null?'':__t)+
'</div>\n              </th>\n              <th class="tooltipInfoTh">\n                <div>\n                  <span class="modalTooltips arangoicon icon_arangodb_info"\n                        title="The approximate cumulative size of all indexes entries in this collection (on the leader). This is an estimate provided by the storage engine and may not be accurate.">\n                  </span>\n                </div>\n              </th>\n            </tr>\n          ';
 } 
__p+='\n\n        ';
 } 
__p+='\n\n      ';
 } 
__p+='\n    </table>\n\n  ';
 } 
__p+='\n\n  <div\n    style="color: #717d90; font-weight: 600; font-size: 12.5pt; padding: 10px; border-bottom: 1px solid rgba(0, 0, 0, .3); border-top: 1px solid rgba(0, 0, 0, .3)">\n    Data distribution\n  </div>\n  <table id="collectionDistributionTable" class="arango-table">\n    ';
 if (figuresData.replicationFactor) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">Replication factor:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.replicationFactor ))==null?'':__t)+
'</div>\n        </th>\n        <th class="tooltipInfoTh">\n          <div>\n                <span class="modalTooltips arangoicon icon_arangodb_info"\n                      title="Determines how many copies of each shard are kept on different DB-Servers.">\n                </span>\n          </div>\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.writeConcern) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">Write concern:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.writeConcern ))==null?'':__t)+
'</div>\n        </th>\n        <th class="tooltipInfoTh">\n          <div>\n                <span class="modalTooltips arangoicon icon_arangodb_info"\n                      title="Determines how many copies of each shard are required to be in sync on the different DB-Servers. If there are less then these many copies in the cluster a shard will refuse to write. Writes to shards with enough up-to-date copies will succeed at the same time however. The value of writeConcern can not be larger than replicationFactor">\n                </span>\n          </div>\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.shardKeys) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">Shard keys:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( JSON.stringify(figuresData.shardKeys) ))==null?'':__t)+
'</div>\n        </th>\n        <th class="tooltipInfoTh">\n          <div>\n                <span class="modalTooltips arangoicon icon_arangodb_info"\n                      title="Contains the names of document attributes that are used to determine the target shard for documents.">\n                </span>\n          </div>\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.distributeShardsLike) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">Distribute shards like:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.distributeShardsLike ))==null?'':__t)+
'</div>\n        </th>\n        <th class="collectionInfoTh">\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.smartJoinAttribute) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">SmartJoin attribute:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.smartJoinAttribute ))==null?'':__t)+
'</div>\n        </th>\n        <th class="collectionInfoTh">\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.hasOwnProperty('isSmartChild') && figuresData.isSmartChild) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">SmartChild:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.isSmartChild ))==null?'':__t)+
'</div>\n        </th>\n        <th class="collectionInfoTh">\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.hasOwnProperty('isDisjoint') && figuresData.isDisjoint) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">Disjoint collection:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.isDisjoint ))==null?'':__t)+
'</div>\n        </th>\n        <th class="collectionInfoTh">\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.numberOfShards) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">Number of shards:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">'+
((__t=( figuresData.numberOfShards ))==null?'':__t)+
'</div>\n        </th>\n        <th class="collectionInfoTh">\n        </th>\n      </tr>\n    ';
 } 
__p+='\n\n    ';
 if (figuresData.shards) { 
__p+='\n      <tr>\n        <th class="collectionInfoTh2">Shards:</th>\n        <th class="collectionInfoTh">\n          <div class="modal-text">\n            <table class="pure-table pure-table-inner">\n              <thead>\n              <tr>\n                <th>Name</th>\n                <th>Documents</th>\n                <th>Leader</th>\n                <th>Follower(s)</th>\n              </tr>\n\n              ';

                let allShards = Object.keys(figuresData.shards);
                let shardCounts = {};
                if (clusterData.hasOwnProperty('shardCounts')) {
                  shardCounts = clusterData.shardCounts;
                }
              allShards.forEach(function(shardName, index) {
                let count;
                if (shardCounts.hasOwnProperty(shardName)) {
                  count = shardCounts[shardName];
                } else {
                  count = 'n/A';
                }
              
__p+='\n\n              <tr>\n                <td>\n                  <span>'+
((__t=( shardName ))==null?'':__t)+
'</span>\n                </td>\n                <td>\n                  <span>'+
((__t=( count ))==null?'':__t)+
'</span>\n                </td>\n                <td>\n                  ';

                    let leaderName = figuresData.shards[shardName][0];
                    try {
                      leaderName = window.arangoHelper.getDatabaseShortName(leaderName);
                    } catch (ignore) {
                    }
                  
__p+='\n                  <span>'+
((__t=( leaderName ))==null?'':__t)+
'</span>\n                </td>\n                <td>\n                  <ul>\n                    ';
 figuresData.shards[shardName].splice(1, figuresData.shards[shardName].length).forEach(follower => {
                      let followerName = follower;
                      try {
                        followerName = window.arangoHelper.getDatabaseShortName(followerName);
                      } catch (ignore) {
                      }
                    
__p+='\n                      <li>\n                        <span>'+
((__t=( followerName ))==null?'':__t)+
'</span>\n                      </li>\n                    ';
 }); 
__p+='\n\n                  </ul>\n                </td>\n              </tr>\n\n              ';
 }); 
__p+='\n\n              </thead>\n            </table>\n          </div>\n        </th>\n        <th class="collectionInfoTh">\n        </th>\n      </tr>\n    ';
 } 
__p+='\n  </table>\n\n</div>\n';
}
return __p;
};
