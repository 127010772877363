module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div class="headerBar">\n    <div class="search-field">\n      <input type="text" value="'+
((__t=(searchString))==null?'':__t)+
'" id="databaseSearchInput" class="search-input" placeholder="Search..."/>\n      <!-- <img id="databaseSearchSubmit" class="search-submit-icon">-->\n      <i id="databaseSearchSubmit" class="fa fa-search"></i>\n    </div>\n    <div class="headerButtonBar">\n        <ul class="headerButtonList">\n          <li class="enabled">\n            <a id="databaseToggle" class="headerButton">\n              <span class="icon_arangodb_settings2" title="Settings"></span>\n            </a>\n          </li>\n        </ul>\n      </div>\n\n  </div>\n\n\n  <div id="databaseDropdown2" class="headerDropdown">\n\n    <div id="databaseDropdown" class="dropdownInner">\n      <ul>\n        <li class="nav-header">Sorting</li>\n        <li><a>\n          <label class="checkbox checkboxLabel">\n            <input class="css-checkbox" type="checkbox" id="dbSortDesc">\n            <i class="fa"></i>\n            Sort descending\n          </label>\n        </a></li>\n      </ul>\n    </div>\n\n  </div>\n\n\n  <div class="contentDiv" id="userManagementView">\n    <div class="tileList pure-u">\n      <div class="tile pure-u-1-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4 pure-u-xl-1-6">\n        <div class="fullBorderBox">\n          <a href="#" id="createDatabase" class="add">\n            <span id="newDatabase" class="pull-left add-Icon"><i class="fa fa-plus-circle"></i></span>\n            Add Database\n          </a>\n        </div>\n      </div>\n      ';
 collection.forEach(function(db) {
          var name = db.get("name");
      
__p+='\n\n      ';
 if (name !== '_system') { 
__p+='\n        <div class="tile pure-u-1-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4 pure-u-xl-1-6 defaultCursor">\n          <div class="fullBorderBox" style="border: 0;">\n            <div class="borderBox"></div>\n            <div class="iconSet">\n              ';
 if (!readOnly) { 
__p+='\n                <span class="icon_arangodb_settings2 editDatabase" id="'+
((__t=(_.escape(name)))==null?'':__t)+
'_edit-database" alt="Edit database" title="Edit database"></span>\n              ';
 } 
__p+='\n            </div>\n            <i class="collection-type-icon fa fa-database defaultCursor"></i>\n            <div class="tileBadge">\n            </div>\n            <h5 class="collectionName" style="margin-left: 0; margin-right: 0;">'+
((__t=(_.escape(name)))==null?'':__t)+
'</h5>\n          </div>\n        </div>\n      ';
};
__p+='\n\n      ';
});
__p+='\n     </div>\n  </div>\n\n';
}
return __p;
};
