module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="arangoToolbar arangoToolbarTop" style="margin-bottom: 5px;">\n  <div class="pull-left">\n    <div style="font-size: 14pt; margin-left: 10px; margin-top: 5px; font-weight: 200">\n      Collections\n    </div>\n  </div>\n  <div class="pull-right">\n    <button id="toggleAllShards" class="button-success">Toggle all</button>\n  </div>\n</div>\n\n<div id="shardsContent" class="innerContent">\n  ';
 var genClass1 = 'pure-u-6-24'; 
__p+='\n  ';
 var genClass2 = 'pure-u-6-24'; 
__p+='\n  ';
 var disabled = ' '; 
__p+='\n  ';
 var collectionName; 
__p+='\n  ';
 var first = 0; 
__p+='\n\n  ';
 _.each(collections, function(collection, name) { 
__p+='\n  <div class="sectionShard" id="'+
((__t=( name ))==null?'':__t)+
'">\n    ';
 collectionName = name 
__p+='\n\n    ';
 if (first === 0) { 
__p+='\n    <div class="sectionHeader pure-g" style="margin-top: -20px;">\n      ';
 first++; 
__p+='\n      ';
 } else { 
__p+='\n      <div class="sectionHeader pure-g">\n        ';
 } 
__p+='\n\n        ';
 var collectionExpanded = (visible.indexOf(name) !== -1); 
__p+='\n        ';
 var collectionInSync = (_.isEqual(collections[name].Current, collections[name].Plan)); 
__p+='\n        ';
 
           var numShards = 0;
           var outOfSyncShards = 0;
           _.each(collection.Plan, function(shard, name) { 
             numShards++;
             var foundOutOfSync = false;
             _.each(shard.followers, function(s) { 
               if (collections[collectionName].Current[name].followers.indexOf(s) === -1) { 
                 foundOutOfSync = true;
               }
             });
             if (foundOutOfSync) {
               outOfSyncShards++;
             }
           });
        
__p+='\n\n        <div class="pure-u-22-24">\n          <div class="title" style="position: relative; top: -4px;">\n            '+
((__t=( name ))==null?'':__t)+
' ('+
((__t=( numShards + ' shard' + (numShards !== 1 ? 's' : '') ))==null?'':__t)+
''+
((__t=( (outOfSyncShards > 0 ? " - " + outOfSyncShards + " syncing..." : "") ))==null?'':__t)+
')\n          </div>\n        </div>\n\n        <div class="pure-u-2-24 shardSyncIcons">\n          ';
 if (collectionExpanded) { 
__p+='\n            <i style="margin-left: 10px; color: #fff;" class="fa fa-arrow-down"></i>\n          ';
 } else { 
__p+='\n            <i style="margin-left: 10px; color: #fff;" class="fa fa-arrow-right"></i>\n          ';
 } 
__p+='\n          ';
 if (collectionInSync) { 
__p+='\n            <i style="margin-left: 10px;" class="fa fa-check-circle"></i>\n          ';
 } else { 
__p+='\n            <i style="margin-left: 10px;" class="fa fa-times-circle"></i>\n          ';
 } 
__p+='\n        </div>\n      </div>\n\n      ';
 if (collectionExpanded) { 
__p+='\n      <div class="sectionShardContent">\n        ';
 } else { 
__p+='\n        <div class="sectionShardContent" style="display: none">\n          ';
 } 
__p+='\n          <div class="pure-g pure-table pure-table-header pure-title" style="clear: both">\n            <div class="pure-table-row">\n              <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left">Shard</div>\n              <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left">Leader</div>\n              <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left">Followers</div>\n              <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' right">Sync</div>\n            </div>\n          </div>\n\n          ';
 var shardClass; 
__p+='\n          ';
 _.each(collection.Plan, function(shard, name) { 
__p+='\n            <div class="pure-g pure-table pure-table-body">\n\n              ';
 if (shard.progress) { 
__p+='\n                ';
 shardClass = 'disabled'; 
__p+='\n              ';
 } 
__p+='\n\n              <div id="'+
((__t=( collectionName ))==null?'':__t)+
'-'+
((__t=( name ))==null?'':__t)+
'" class="pure-table-row noHover '+
((__t=( shardClass ))==null?'':__t)+
'"\n                   collection="'+
((__t=( collectionName ))==null?'':__t)+
'" shard="'+
((__t=( name ))==null?'':__t)+
'" leader="'+
((__t=( shard.leader ))==null?'':__t)+
'">\n\n                ';
 if (shard.leader.substring(0, 1) === '_') { 
__p+='\n                  ';
 shard.leader = shard.leader.slice(1) 
__p+='\n                ';
 } 
__p+='\n\n                <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left">'+
((__t=( name ))==null?'':__t)+
'</div>\n                <div class="shardLeader '+
((__t=( genClass1 ))==null?'':__t)+
' positive left"><span>'+
((__t=( shard.leader ))==null?'':__t)+
'</span></div>\n\n                ';
 var foundLeader = false; 
__p+='\n                ';
 _.each(shard.followers, function(s) { 
__p+='\n                  ';
 if (s === shard.leader) { 
__p+='\n                    ';
 foundLeader = true; 
__p+='\n                  ';
 } 
__p+='\n                ';
 }); 
__p+='\n\n                ';
 var shardsNotInSync = 0; 
__p+='\n                ';
 if (foundLeader) { 
__p+='\n                  <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid"><i class="fa fa-circle-o-notch fa-spin"></i></div>\n                ';
 } else { 
__p+='\n                  ';
 if (shard.followers.length === 0) { 
__p+='\n                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left"> no followers</div>\n                  ';
 } else { 
__p+='\n                    ';
 var string = ''; 
__p+='\n                    ';
 var counter2 = 0; 
__p+='\n                    ';
 var shardCssClass = 'inSync'; 
__p+='\n\n                    ';
 _.each(shard.followers, function(s) { 
__p+='\n                      ';
 if (collections[collectionName].Current[name].followers.indexOf(s) > -1) { 
__p+='\n                        ';
 shardCssClass = 'inSync'; 
__p+='\n                      ';
 } else { 
__p+='\n                        ';
 shardCssClass = 'notInSync'; 
__p+='\n                        ';
 shardsNotInSync++; 
__p+='\n                      ';
 } 
__p+='\n                      ';
 if (serversFailed[s]) { 
__p+='\n                        ';
 shardCssClass = 'failed'; 
__p+='\n                      ';
 } 
__p+='\n\n                      ';
 if (counter2++ > 0) { 
__p+='\n                        ';
 string += ', '; 
__p+='\n                      ';
 } 
__p+='\n                      ';
 string += '<span class="' + shardCssClass + '">' + s + '</span>'; 
__p+='\n                    ';
 }); 
__p+='\n\n                    <div class="shardFollowers '+
((__t=( genClass2 ))==null?'':__t)+
' left">'+
((__t=( string ))==null?'':__t)+
'</div>\n                  ';
 } 
__p+='\n\n                  ';
 if (collectionInSync) { 
__p+='\n                    <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left progressWrapper"><i class="fa fa-check-circle"></i></div>\n                  ';
 } else { 
__p+='\n                    ';
 if (shardsNotInSync > 0) { 
__p+='\n                      <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left progressWrapper"><span class="shardProgress"><i\n                                  class="fa fa-circle-o-notch fa-spin fa-fw"></i></span></div>\n                    ';
 } else { 
__p+='\n                      <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left progressWrapper"><i class="fa fa-check-circle"></i></div>\n                    ';
 } 
__p+='\n                  ';
 } 
__p+='\n\n                ';
 } 
__p+='\n\n              </div>\n            </div>\n\n          ';
 }); 
__p+='\n        </div>\n\n      </div>\n      ';
 }); 
__p+='\n\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
