module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <ul class="subMenuEntries top">\n    <li class="subMenuEntry pull-left">\n      <div class="breadcrumb"></div>\n    </li>\n    <li id="healthStatus" class="infoEntry subMenuEntry pull-right positive">\n      <a class="info health-info">HEALTH: </a>\n      ';
 if (frontendConfig.clusterApiJwtPolicy !== 'jwt-all') { 
__p+='\n        <a class="state health-state">GOOD</a>\n      ';
 } else { 
__p+='\n        <a class="state health-state" title="cluster API is inaccessible due to missing permissions">n/a</a>\n      ';
 } 
__p+='\n      <a class="icon health-icon">\n        <i class="fa fa-check-circle"></i>\n      </a>\n    </li>\n    <li id="dbStatus" class="infoEntry subMenuEntry pull-right positive">\n      <a class="info">DB:</a>\n      <a class="state"><span>'+
((__t=( _.escape(currentDB.name) ))==null?'':__t)+
'</span></a>\n      <a class="icon db-icon default-icon">\n        <i class="fa fa-refresh"></i>      \n      </a>\n    </li>\n    <li id="userBar" class="infoEntry subMenuEntry pull-right" style="margin-right: 10px;">\n    </li>\n  </ul>\n\n  <div id="licenseInfoArea">\n  </div>\n\n  <ul class="subMenuEntries bottom">\n  </ul>\n\n';
}
return __p;
};
